import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import logo from "../../assets/css/images/bloglogo.png"
import BlogSidebar from "../../components/blog-sidebar"
import { articles } from "../blog"

export default () => (
  <Layout isHome={false} pageId="blogpage" logo={logo}>
    <SEO title="Prototyping Your Workflow" />
    <div id="content">
      <ul id="content_article">
        <li
          id="post-115"
          className="post-115 post type-post status-publish format-standard hentry category-blog"
        >
          <h1>Prototyping Your Workflow</h1>
          <p className="postdetails">
            Posted on <span>June 03, 2014</span>
          </p>
          <div className="wrap_blog_text">
            <h3>Technique is easy, talking is hard</h3>
            <p>
              It’s easy to get fixated on the benefits of specific tools and
              techniques, and to assume that those benefits are self-evident to
              everyone. But over the course of the past year, it’s dawned on us
              that meeting the demands of our multi-device web is less a problem
              of technique, and more one of communication. Sometimes people just
              need to understand why you want them to change how they work.
            </p>
            <p>
              You will probably need to make your case differently for each
              person on your team, then. If you’re a designer, it could mean
              explaining to your developer teammate that you would like to start
              breaking things into a design system so that you don’t have to do
              20 different iterations of the same page layout. For developers,
              you might have to convince your boss that a style prototype will
              be the best way to present a site to your client.
            </p>

            <h3>Limit your focus</h3>
            <p>
              A mentor of mine once told me that programming (and especially
              programming for the web) boils down to reducing the number of
              “unknowns” on a project to a manageable number. One is fine, two
              is a stretch, and three is asking for trouble. If you think
              exploring HTML/CSS wireframes could have a positive impact on your
              work, introduce just that one thing. Most projects have enough
              built-in friction without adding or changing multiple processes at
              the same time.
            </p>
            <p>
              A good idea that didn’t quite work in the past: we had created a
              static style guide for a previous project that had quickly become
              outdated and was ultimately discarded. But we still thought the
              idea had merit. So when you gather as a team, think about past
              good ideas that might have stalled, and whether they could work if
              you approached them differently.
            </p>

            <h3>Align your tools and techniques with your team</h3>
            <p>
              One of the recurring discussions we have at our studio is: “Should
              our designers learn markup and start doing some of this design in
              the browser?” We’ve heard a lot of persuasive arguments for it,
              but in the end, we decided that the main focus should be how to
              get our designs into the browser earlier in the process, instead
              of who should be doing that work.
            </p>
            <p>
              This led us to try pairing designers and developers early on in a
              project, and having the developer create markup that “waterskis”
              behind the designer’s sketches and Photoshop explorations. We’ve
              found that doing it this way takes advantage of our team’s
              individual strengths. It also means that our developers are
              providing feedback that makes it into design iterations while they
              are still malleable.
            </p>
            <p>
              We’re currently in the middle of a redesign project for a literary
              magazine, and we’ve found that the rough HTML/CSS mockups created
              by our developer helped us pose the right questions to our team’s
              designer. Giving our designer a specific problem to solve (“These
              titles take up too much space at narrow widths”) allowed her to
              judge the problem in the context of the entire design. She could
              then explain what she was trying to accomplish visually, and even
              find solutions that extended beyond the immediate issue we were
              trying to solve. She’d look at the screen while we squished the
              browser back and forth, and then say something like, “If you move
              the titles below the photos this whole problem goes away.”
              Stepping away from dogmatic ideas of who should be doing what
              allowed her to focus on doing what she did best, which was solving
              visual problems.
            </p>
          </div>
        </li>
      </ul>

      <BlogSidebar articles={articles} />
    </div>
  </Layout>
)
